import { BreadcrumbBar, LayoutWithSideRail, PhoenixBaseCard } from 'componix'
import { UUID } from 'crypto'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetAncestors } from '../cache/ancestorCache'
import { useGetComboDataByCoverageId } from '../cache/comboCache'
import { useGetPolicyListByCoverageId } from '../cache/policyCache'
import ComboGroupMembershipHistory from '../components/Coverage/ComboGroupMembershipHistory'
import CoverageGroupOwnership from '../components/Coverage/CoverageGroupOwnership'
import { CoverageGroupProfile } from '../components/Coverage/CoverageGroupProfile'
import PoliciesTable from '../components/Coverage/PoliciesTable'
import { ExperienceRating } from '../components/ExperienceRating'
import MovePolicyModal from '../components/Modals/MovePolicyModal'
import PrimaryCoverageIdentifier from '../components/PrimaryCoverageIdentifier'
import RelatedCoverageIDsCard from '../components/RelatedCoverageIDs'
import { useNavbar } from '../contexts/NavbarContext'
import { useMovePolicyToCoverage } from '../hooks/useMovePolicy'
import useSetAlertDetails from '../hooks/workQueue/useSetAlertDetails'
import { HierarchyTypes } from '../utils/hierarchy.enum'

export const Coverages = () => {
  const { coverageId } = useParams()
  const { pageStyle } = useNavbar()

  const {
    data: comboData,
    isPending: isComboDataLoading,
    isError: isComboDataError,
  } = useGetComboDataByCoverageId(coverageId as string)

  const { data: ancestorData } = useGetAncestors(comboData?.coverageGuid as UUID, HierarchyTypes.COVERAGE)

  const {
    data: policyList,
    isPending: isPolicyListLoading,
    isRefetching: isPolicyListRefetching,
    isError: isPolicyListError,
  } = useGetPolicyListByCoverageId(comboData?.coverageGuid as string)

  const {
    isModalOpen: isMovePolicyModalOpen,
    openModal: openMovePolicyModal,
    closeModal,
    setFoundMatch,
    match,
    successMove,
    isMatchLoading,
    isAtConfirm,
    handleConfirmMove,
    policiesToMove,
    setPoliciesToMove,
  } = useMovePolicyToCoverage({ coverage: { guid: comboData?.coverageGuid, identifier: coverageId } })

  useSetAlertDetails([isPolicyListError, isComboDataError])

  return (
    <div style={pageStyle}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Combo ${comboData?.comboId}`,
            to: `/combos/${comboData?.comboGuid}`,
          },
          {
            label: `Coverage ${coverageId}`,
            to: `/coverages/${coverageId}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <>
            <PhoenixBaseCard cardTitle={'Policies'}>
              <PoliciesTable
                data-testid="policies-table"
                policyListData={policyList}
                isLoading={isPolicyListLoading}
                isRefetching={isPolicyListRefetching}
                openMovePolicyModal={openMovePolicyModal}
                setPoliciesToMove={setPoliciesToMove}
              />
            </PhoenixBaseCard>
            <CoverageGroupProfile coverageId={coverageId as string} />
            <ComboGroupMembershipHistory coverageId={coverageId as string} />
            <CoverageGroupOwnership coverageId={coverageId as string} />
          </>
        }
        sideRailComponentArray={[
          <PrimaryCoverageIdentifier
            key=""
            combo={ancestorData?.combo}
            coverage={ancestorData?.coverage}
            isLoading={isComboDataLoading}
          />,
          <ExperienceRating combo={ancestorData?.combo} key="er" />,
          <RelatedCoverageIDsCard
            comboId={ancestorData?.combo?.guid}
            coverageId={ancestorData?.coverage?.guid}
            key=""
          />,
        ]}
      ></LayoutWithSideRail>
      <MovePolicyModal
        isOpen={isMovePolicyModalOpen}
        policies={policiesToMove}
        onCancel={closeModal}
        match={match}
        setFoundMatch={setFoundMatch}
        successMove={successMove}
        isMatchLoading={isMatchLoading}
        isAtConfirm={isAtConfirm}
        handleConfirmMove={handleConfirmMove}
      />
    </div>
  )
}
